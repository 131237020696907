export enum ProductConditions {
  unknown = '-1',
  new = '1',
  used = '2',
}

export interface ErcoProduct {
  id: number;
  accountId: number;
  is_active: boolean;
  fecha: string;
  name: string;
  imageURL: string | null;
  description: string | null;
  accesories: string | null;
  model: string | null;
  warranty: string | null;
  year: number | null;
  condition: ProductConditions;
  timeForShipping: string | null;
  price: number;
}

export interface ErcoInvoice {
  id: number;
  is_active: boolean;
  fecha: string;
  productId: number;
  discountAmount: number | null;
  discountPercentage: number | null;
  upfrontPaymentAmount: number | null;
  upfrontPaymentPercentage: number | null;
  twelveMonths: boolean;
  twentyFourMonths: boolean;
  thirtySixMonths: boolean;
  fortyEightMonths: boolean;
  sixtyMonths: boolean;
  customerName: string;
  customerFirstLastName: string;
  customerSecondLastName: string;
  customerEmail: string;
  customerPhone: string;
  pdfURL: string;
}

export interface FactorSheet {
  12: {
    factor: number;
    residualValue: number;
  };
  24: {
    factor: number;
    residualValue: number;
  };
  36: {
    factor: number;
    residualValue: number;
  };
  48: {
    factor: number;
    residualValue: number;
  };
  60: {
    factor: number;
    residualValue: number;
  };
}
