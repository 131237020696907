import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { pdf } from '@react-pdf/renderer';
import * as Yup from 'yup';
import {
  ErcoInvoice,
  ErcoProduct,
  FactorSheet,
  ProductConditions,
} from '../../interfaces/products';
import { useCotizadorProvider } from '../../context/CotizadorMainContext';
import { CRUDModes } from '../../../../interfaces/CRUD';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import DialogCustomTitle from '../../../../components/DialogCustomTitle/DialogCustomTitle';
import CenteredForm from '../../../../index.styled';
import { PrimarySubtitle } from '../../../../components/PrimarySubtitle/PrimarySubtitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import { DisclaimerText } from '../../../../components/DisclaimerText/DisclaimerText';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import NoImage from '../../../../assets/img/no-image.jpg';
import EmptyClosure from '../../../../utils/closures';
import { CMTextfield } from '../../../../components/Forms/CMTextfield';
import {
  regularPaymentOptions,
  thirtyFiveResidualOptions,
} from '../../interfaces/businessLogic';
import InvoicePrintable from './InvoicePrintable';
import { getBase64 } from '../../../../utils/fns';
import { FlexContainer } from '../../../../components/FlexContainer/FlexContainer';
import {
  MinValidationMessage,
  RequiredMessage,
} from '../../../../utils/validationMessages';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: ErcoInvoice;
  product: ErcoProduct;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: ErcoInvoice = {
  id: 0,
  is_active: true,
  fecha: '',
  productId: 0,
  discountAmount: 0,
  discountPercentage: 0,
  upfrontPaymentAmount: 0,
  upfrontPaymentPercentage: -1,
  twelveMonths: false,
  twentyFourMonths: false,
  thirtySixMonths: false,
  fortyEightMonths: false,
  sixtyMonths: false,
  customerName: '',
  customerFirstLastName: '',
  customerSecondLastName: '',
  customerEmail: '',
  customerPhone: '',
  pdfURL: '',
};

function ErcoProductForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
  product,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    user,
    handleLogoutError,
    token,
  } = useCotizadorProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState<string>('');
  const [thirtyFiveResidual, setThirtyFiveResidual] = useState<boolean>(false);
  const [factorSheet, setFactorSheet] = useState<FactorSheet>();

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, [values]);

  const validationSchema = Yup.object({
    upfrontPaymentPercentage: Yup.number()
      .min(1, MinValidationMessage(1))
      .required(RequiredMessage),
  });

  const formik = useFormik<ErcoInvoice>({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (productValues) => {
      try {
        setError('');
        setLoading(true);
        if (!factorSheet) {
          setError('Error al generar la cotización, intente de nuevo');
        }
        const blob = await pdf(
          InvoicePrintable({
            invoice: formik.values,
            product,
            factorSheet: factorSheet as FactorSheet,
          }),
        ).toBlob();
        const transformedFile = await getBase64(blob);
        const res = await axios.post<ErcoInvoice>(
          `${process.env.REACT_APP_SERVER_URL}/erco/${
            mode === CRUDModes.Create ? 'save' : 'update'
          }-invoice`,
          {
            invoice: {
              ...productValues,
              fecha: moment
                .utc()
                .utcOffset(-300)
                .toISOString(true)
                .slice(0, 19)
                .replace('T', ' '),
              productId: product.id,
            },
            file: transformedFile,
          },
          {
            headers: { 'access-token': token ?? '' },
          },
        );
        setLoading(false);
        setAlertType(AlertTypes.Success);
        setAlertMessage(`Información guardada con éxito`);
        setOpenAlert(true);
        console.log(res);
        await formik.setFieldValue('pdfURL', res.data.pdfURL);
        setError('');
      } catch (e: any) {
        setFactorSheet(undefined);
        handleLogoutError(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar producto: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const resetConditions = async () => {
    await formik.setFieldValue('twelveMonths', false);
    await formik.setFieldValue('twentyFourMonths', false);
    await formik.setFieldValue('thirtySixMonths', false);
    await formik.setFieldValue('fortyEightMonths', false);
    await formik.setFieldValue('sixtyMonths', false);
    await formik.setFieldValue('upfrontPaymentPercentage', -1);
    await formik.setFieldValue('upfrontPaymentAmount', 0);
  };

  const upfrontPaymentAmount = useMemo(() => {
    const creditAmount = formik.values.discountPercentage
      ? product.price - (product.price * formik.values.discountPercentage) / 100
      : product.price;
    if (
      formik.values.upfrontPaymentPercentage &&
      formik.values.upfrontPaymentPercentage > 0
    ) {
      return (creditAmount * formik.values.upfrontPaymentPercentage) / 100;
    }
    return 0;
  }, [
    formik.values.discountPercentage,
    formik.values.upfrontPaymentPercentage,
    product.price,
  ]);

  const findResidual = useCallback(
    (months: number) => {
      if (!thirtyFiveResidual) {
        if (
          formik.values.upfrontPaymentPercentage &&
          Number(formik.values.upfrontPaymentPercentage) > 0
        ) {
          const paymentOptions = regularPaymentOptions
            .filter(
              (opt) =>
                opt.upfrontPayment ===
                (formik.values.upfrontPaymentPercentage || 0) / 100,
            )
            .sort((a, b) => a.months - b.months);
          if (months === 12) {
            return paymentOptions[0].residualAmount * 100;
          }
          if (months === 24) {
            return paymentOptions[1].residualAmount * 100;
          }
          if (months === 36) {
            return paymentOptions[2].residualAmount * 100;
          }
          if (months === 48) {
            return paymentOptions[3].residualAmount * 100;
          }
          return paymentOptions[4].residualAmount * 100;
        }
        return 0;
      }
      return 35;
    },
    [formik.values.upfrontPaymentPercentage, thirtyFiveResidual],
  );

  const prepareFormikForSubmit = async () => {
    await formik.setFieldValue('upfrontPaymentAmount', upfrontPaymentAmount);
    await formik.setFieldValue(
      'discountAmount',
      formik.values.discountPercentage
        ? (product.price * formik.values.discountPercentage) / 100
        : 0,
    );
    if (thirtyFiveResidual) {
      const paymentOptions = thirtyFiveResidualOptions
        .filter(
          (opt) =>
            opt.upfrontPayment === (formik.values.upfrontPaymentPercentage || 0) / 100,
        )
        .sort((a, b) => a.months - b.months);
      setFactorSheet({
        12: {
          factor: paymentOptions[0].factor,
          residualValue: 0.35,
        },
        24: {
          factor: paymentOptions[1].factor,
          residualValue: 0.35,
        },
        36: {
          factor: paymentOptions[2].factor,
          residualValue: 0.35,
        },
        48: {
          factor: 0,
          residualValue: 0.35,
        },
        60: {
          factor: 0,
          residualValue: 0.35,
        },
      });
    } else {
      const paymentOptions = regularPaymentOptions
        .filter(
          (opt) =>
            opt.upfrontPayment === (formik.values.upfrontPaymentPercentage || 0) / 100,
        )
        .sort((a, b) => a.months - b.months);
      setFactorSheet({
        12: {
          factor: paymentOptions[0].factor,
          residualValue: paymentOptions[0].residualAmount,
        },
        24: {
          factor: paymentOptions[1].factor,
          residualValue: paymentOptions[1].residualAmount,
        },
        36: {
          factor: paymentOptions[2].factor,
          residualValue: paymentOptions[2].residualAmount,
        },
        48: {
          factor: paymentOptions[3].factor,
          residualValue: paymentOptions[3].residualAmount,
        },
        60: {
          factor: paymentOptions[4].factor,
          residualValue: paymentOptions[4].residualAmount,
        },
      });
    }
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='Cotización'
      />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <FormikProvider value={formik}>
            <CenteredForm onSubmit={formik.handleSubmit}>
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
              >
                <Grid item xs={12}>
                  <PrimarySubtitle mobile>Cotizar producto</PrimarySubtitle>
                  <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                    Cotiza un arrendamiento a partir de los datos del producto guardado
                  </PrimaryDescription>
                </Grid>
                {!factorSheet ? (
                  <>
                    <Grid item xs={12} md={4} lg={3}>
                      <img
                        src={product.imageURL || NoImage}
                        alt='Imagen del producto'
                        style={{
                          width: '100%',
                          aspectRatio: '1 / 1',
                          objectFit: 'cover',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Producto:</b> {product.name}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Descripción:</b> {product.description}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Accesorios:</b> {product.accesories}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Garantía:</b> {product.warranty}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Estado:</b>{' '}
                        {product.condition === ProductConditions.new ? 'Nuevo' : 'Usado'}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Precio:</b>{' '}
                        {product.price -
                          (formik.values.discountPercentage
                            ? (product.price * formik.values.discountPercentage) / 100
                            : 0)}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Modelo:</b> {product.model}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Año:</b> {product.year}
                      </PrimaryDescription>
                      <PrimaryDescription
                        style={{ textAlign: 'start', marginBottom: '1rem' }}
                      >
                        <b>Tiempo de entrega:</b> {product.timeForShipping}
                      </PrimaryDescription>
                    </Grid>
                    <Grid item xs={12}>
                      <PrimarySubtitle style={{ margin: '1rem 0 1rem 0' }} mobile>
                        Términos de arrendamiento
                      </PrimarySubtitle>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            name='set35'
                            id='set35'
                            checked={thirtyFiveResidual}
                            onChange={(e) => {
                              setThirtyFiveResidual(e.target.checked);
                              resetConditions().then(EmptyClosure).catch(EmptyClosure);
                            }}
                          />
                        }
                        label={
                          <PrimaryDescription style={{ margin: 0, fontWeight: 500 }}>
                            ¿Aplicar valor residual fijo del 35%?
                          </PrimaryDescription>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        id='discountPercentage'
                        label='Porcentaje de descuento'
                        type='number'
                        placeholder='Porcentaje de descuento'
                        value={formik.values.discountPercentage || null}
                        name='discountPercentage'
                        onChange={async (e) => {
                          if (e.target.value) {
                            await formik.setFieldValue(
                              'discountPercentage',
                              Number(e.target.value),
                              true,
                            );
                          } else {
                            await formik.setFieldValue('discountPercentage', 0, false);
                          }
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        id='discountAmount'
                        label='Monto descuento'
                        type='number'
                        placeholder='Monto descuento'
                        value={
                          formik.values.discountPercentage
                            ? (product.price * formik.values.discountPercentage) / 100
                            : 0
                        }
                        name='discountAmount'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        select
                        id='upfrontPaymentPercentage'
                        label='Porcentaje de anticipo'
                        value={String(formik.values.upfrontPaymentPercentage)}
                        name='upfrontPaymentPercentage'
                        style={{ marginBottom: '1rem' }}
                        error={
                          formik.touched.upfrontPaymentPercentage &&
                          Boolean(formik.errors.upfrontPaymentPercentage)
                        }
                        helperText={
                          formik.touched.upfrontPaymentPercentage &&
                          formik.errors.upfrontPaymentPercentage
                        }
                        onChange={formik.handleChange}
                      >
                        <MenuItem value='-1' disabled>
                          Seleccione una opción
                        </MenuItem>
                        <MenuItem value='10'>10%</MenuItem>
                        <MenuItem value='15'>15%</MenuItem>
                        <MenuItem value='20'>20%</MenuItem>
                        <MenuItem value='25'>25%</MenuItem>
                        <MenuItem value='30'>30%</MenuItem>
                        <MenuItem value='35'>35%</MenuItem>
                        {!thirtyFiveResidual && <MenuItem value='40'>40%</MenuItem>}
                        {!thirtyFiveResidual && <MenuItem value='45'>45%</MenuItem>}
                        {!thirtyFiveResidual && <MenuItem value='50'>50%</MenuItem>}
                      </CMTextfield>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CMTextfield
                        id='upfrontPaymentAmount'
                        label='Monto anticipo'
                        placeholder='Monto anticipo'
                        value={upfrontPaymentAmount}
                        name='upfrontPaymentAmount'
                        onChange={formik.handleChange}
                        disabled
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PrimarySubtitle style={{ margin: '0 0 1rem 0' }} mobile>
                        Plazos a cotizar
                      </PrimarySubtitle>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              name='twelveMonths'
                              id='twelveMonths'
                              checked={formik.values.twelveMonths}
                              onChange={formik.handleChange}
                            />
                          }
                          label={
                            <PrimaryDescription style={{ margin: 0 }}>
                              12 meses ({findResidual(12)}% residual)
                            </PrimaryDescription>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              name='twentyFourMonths'
                              id='twentyFourMonths'
                              checked={formik.values.twentyFourMonths}
                              onChange={formik.handleChange}
                            />
                          }
                          label={
                            <PrimaryDescription style={{ margin: 0 }}>
                              24 meses ({findResidual(24)}% residual)
                            </PrimaryDescription>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              name='thirtySixMonths'
                              id='thirtySixMonths'
                              checked={formik.values.thirtySixMonths}
                              onChange={formik.handleChange}
                            />
                          }
                          label={
                            <PrimaryDescription style={{ margin: 0 }}>
                              36 meses ({findResidual(36)}% residual)
                            </PrimaryDescription>
                          }
                        />
                        {!thirtyFiveResidual && (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color='primary'
                                  name='fortyEightMonths'
                                  id='fortyEightMonths'
                                  checked={formik.values.fortyEightMonths}
                                  onChange={formik.handleChange}
                                />
                              }
                              label={
                                <PrimaryDescription style={{ margin: 0 }}>
                                  48 meses ({findResidual(48)}% residual)
                                </PrimaryDescription>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color='primary'
                                  name='sixtyMonths'
                                  id='sixtyMonths'
                                  checked={formik.values.sixtyMonths}
                                  onChange={formik.handleChange}
                                />
                              }
                              label={
                                <PrimaryDescription style={{ margin: 0 }}>
                                  60 meses ({findResidual(60)}% residual)
                                </PrimaryDescription>
                              }
                            />
                          </>
                        )}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PrimarySubtitle style={{ margin: '0 0 1rem 0' }} mobile>
                        Datos del cliente
                      </PrimarySubtitle>
                      <CMTextfield
                        id='customerName'
                        label='Nombre(s)'
                        placeholder='Nombre(s)'
                        value={formik.values.customerName}
                        name='customerName'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <CMTextfield
                        id='customerFirstLastName'
                        label='Apellido paterno'
                        placeholder='Apellido paterno'
                        value={formik.values.customerFirstLastName}
                        name='customerFirstLastName'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <CMTextfield
                        id='customerSecondLastName'
                        label='Apellido materno'
                        placeholder='Apellido materno'
                        value={formik.values.customerSecondLastName}
                        name='customerSecondLastName'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <CMTextfield
                        id='customerEmail'
                        label='Email cliente'
                        placeholder='Email cliente'
                        value={formik.values.customerEmail}
                        name='customerEmail'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <CMTextfield
                        id='customerPhone'
                        label='Teléfono cliente'
                        placeholder='Teléfono cliente'
                        value={formik.values.customerPhone}
                        name='customerPhone'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                  </>
                ) : null}
                {error ? (
                  <DisclaimerText
                    style={{ color: 'red', textAlign: 'center', marginBottom: '1rem' }}
                  >
                    {error}
                  </DisclaimerText>
                ) : null}
                <Grid item xs={12}>
                  {formik.values.pdfURL ? (
                    <>
                      <PrimarySubtitle mobile>Comparte la cotización</PrimarySubtitle>
                      <FlexContainer
                        direction={mobile ? 'column' : 'row'}
                        justifyContent={mobile ? 'flex-start' : 'space-around'}
                        style={{
                          gap: mobile ? '1rem' : '0',
                          marginTop: '1.5rem',
                        }}
                      >
                        <PrimaryButton
                          loading={loading}
                          type='button'
                          style={{ backgroundColor: '#0077B5', color: 'white' }}
                          onClick={async () => {
                            const a = document.createElement('a');
                            a.download = 'cotizacion.pdf';
                            a.href = formik.values.pdfURL;
                            a.style.display = 'none';
                            a.target = '_blank';
                            a.referrerPolicy = 'no-referrer';

                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                          }}
                        >
                          Descargar
                        </PrimaryButton>
                        <PrimaryButton
                          loading={loading}
                          style={{ backgroundColor: '#25D366', color: 'white' }}
                          type='button'
                          onClick={async () => {
                            const a = document.createElement('a');
                            a.href = `https://api.whatsapp.com/send?text=Hola ${formik.values.customerName}, te envío la cotización del producto que solicitaste. Haz click en el enlace para revisarla: ${formik.values.pdfURL}`;
                            a.style.display = 'none';
                            a.target = '_blank';
                            a.referrerPolicy = 'no-referrer';
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                          }}
                        >
                          Enviar por WhatsApp
                        </PrimaryButton>
                        <PrimaryButton
                          loading={loading}
                          type='button'
                          style={{ backgroundColor: '#0077B5', color: 'white' }}
                          onClick={async () => {
                            const a = document.createElement('a');
                            a.href = `mailto:${formik.values.customerEmail}?subject=Cotización Arrendamiento&body=Hola ${formik.values.customerName}, te envío la cotización del producto ${product.name} que solicitaste. Haz click en el enlace para revisarla: ${formik.values.pdfURL}`;
                            a.style.display = 'none';
                            a.target = '_blank';
                            a.referrerPolicy = 'no-referrer';
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                          }}
                        >
                          Enviar correo
                        </PrimaryButton>
                      </FlexContainer>
                    </>
                  ) : null}
                  {!factorSheet ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        const errors = await formik.validateForm();
                        if (errors.upfrontPaymentPercentage) {
                          setError('Por favor, seleccione un porcentaje de anticipo');
                          return;
                        }
                        await prepareFormikForSubmit();
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%' }}
                    >
                      Cotizar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              </Grid>
            </CenteredForm>
          </FormikProvider>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

ErcoProductForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default ErcoProductForm;
